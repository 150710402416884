import { atom } from "recoil";
import { EMRPatient } from "@/lib/types/patient";
import { EMRPhysician } from "@/lib/types/physician";
import { EMRAppointment } from "@/lib/types/appointment";
import { NoteType, OrderType, PatientFileType, TemplaterEncounterNote } from "@/lib/types/encounterNote";
import { AddressBookOrganization, AddressBookOther, AddressBookPhysician } from "@/lib/types/addressBook";
import { Pharmacy } from "@/lib/types/pharmacy";

export type ClinicalChartTabItems = "patientChart" | "encounter";
export type TemplateManagerTabItems = "templateManager" | "componentManager" | "hotPhrasesManager";

export const selectedPatientAtom = atom({
  key: "selectedPatient",
  default: {} as EMRPatient,
});

export const userAtom = atom({
  key: "user",
  default: {} as EMRPhysician,
});

export const pharmacyListAtom = atom({
  key: "pharmacyList",
  default: [] as Pharmacy[],
});

export const selectedPharmacyAtom = atom({
  key: "selectedPharmacy",
  default: {} as Pharmacy,
});

export const selectedAddressBookPharmacyAtom = atom({
  key: "selectedAddressBookPharmacy",
  default: {} as Pharmacy,
});

export const selectedAppointmentAtom = atom({
  key: "selectedAppointment",
  default: {} as EMRAppointment,
});

export const encounterNoteIdAtom = atom({
  key: "encounterNoteId",
  default: null as number | null,
});

export const clinicalChartTabAtom = atom({
  key: "clinicalChartTab",
  default: "patientChart" as ClinicalChartTabItems,
});

export const templateManagerTabAtom = atom({
  key: "templateManagerTab",
  default: "templateManager" as TemplateManagerTabItems,
});

interface IUnsavedState {
  unsaved: boolean;
}

export const unsavedStateAtom = atom({
  key: "unsavedState",
  default: { unsaved: false } as IUnsavedState,
});

export const selectedAddressBookOrganizationAtom = atom({
  key: "selectedAddressBookOrganization",
  default: {} as AddressBookOrganization,
});

export const selectedAddressBookOtherAtom = atom({
  key: "selectedAddressBookOther",
  default: {} as AddressBookOther,
});

export const selectedAddressBookPhysicianAtom = atom({
  key: "selectedAddressBookPhysician",
  default: {} as AddressBookPhysician,
});

export const orderDraftsAtom = atom({
  key: "orderDrafts",
  default: [] as OrderType[],
});
